.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  background-color: #f37658;
  --background-color: #f37658;
}

.Login-component {
  background: url(images/paper-bag-strip.png) center center;
  background-repeat: 'repeat';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'AmaticSC';
  src: local('AmaticSC'), url(./fonts/AmaticSC/AmaticSC-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AmaticSC';
    src: local('AmaticSC'), url(./fonts/AmaticSC/AmaticSC-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'LibreBaskerville';
    src: local('LibreBaskerville'), url(./fonts/LibreBaskerville/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LuloCleanOneBold';
  src: local('LuloCleanOneBold'), url(./fonts/LuloCleanOneBold/LuloCleanOneBold-Regular.ttf) format('truetype');
}